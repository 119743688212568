.rbc-calendar {
  width: 100%;
  height: 48rem;
  background: #F7F6FB;
}
  .rbc-month-view {
    border-radius: 20px;
    background-color: white;
    height: 100%;
  }
    .rbc-month-row{
      overflow: unset;
      height: 100%; 
    }

   .rbc-month-view .rbc-row-content{
      height: min-content;
      
      display: flex;
      
      flex-direction: column;
      height: 100%;
      left: 8px;
      top: 8px;

    }
    .rbc-event{
      width: 95%;
    }
  
    .rbc-month-view .rbc-row-content .rbc-row:first-child{
      order: 100;
      position: absolute;
      bottom: 2px;
      width: 100%;
      
    }
   .rbc-show-more{
    position: relative;
    
   }
    .rbc-button-link.rbc-show-more{
      color:#074ee8;
      /* position: absolute; */
      
      font-size: 8px;
      margin-left: 8px;
      margin-top: 30px;
      
      
      
    }
    
   
  
        
      
      .rbc-day-bg.rbc-off-range-bg{
        background-color:transparent;
        
      }


    .rbc-row.rbc-month-header{
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      background-color: #F8F9FA;
    }

    .rbc-header.rbc-header{
      border-left: none;
    }
       .rbc-header{
        
       border-left: 0px;
      
       } 
 

  .rbc-time-view {
    border-width: 0;
  }


    .rbc-label {
    display: none;
    }
    .rbc-allday-cell {
    max-height: unset;
    }
    .rbc-time-content {
    display: none;
    }
 
    .rbc-header{
      border-bottom-width: 0;
      background-color: #F8F9FA;
    }

    .rbc-time-header-content{
      border-width: 1px;
      border-radius: 10px;
      background-color:white;
    
    }
    
  

   

   